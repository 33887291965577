<template>
  <div>
    <div class="card mb-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h6 class="mb-0">
          <fa-icon
            :icon="['fa', 'graduation-cap']"
            class="mr-3 text-secondary"
          />Rencana Pendidikan
        </h6>
        <button
          v-if="!editmode"
          @click="editTrigger"
          class="btn btn-sm btn-primary"
        >
          <fa-icon :icon="['fa', 'pen']" class="mr-2" />Ubah
        </button>
        <div v-if="editmode" class="float-right">
          <button @click="action" class="btn btn-sm btn-success mx-1">
            <fa-icon :icon="['fa', 'save']" class="mr-2" />Simpan
          </button>
          <button @click="editmode = false" class="btn btn-sm btn-danger ml-1">
            <fa-icon :icon="['fa', 'times']" class="mr-2" />Batal
          </button>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12" v-if="!editmode">
            <div class="row">
              <label class="col-sm-4 col-form-label">Mondok</label>
              <div class="col-sm-7">
                <input
                  :value="logical(pesertadidik.is_mondok)"
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>

            <div class="row">
              <label class="col-sm-4 col-form-label">Jenis Pendaftaran</label>
              <div class="col-sm-7">
                <input
                  :value="
                    pesertadidik.jenis_daftar ? pesertadidik.jenis_daftar : '-'
                  "
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>

            <hr class="my-2" />

            <div class="row">
              <label class="col-sm-4 col-form-label">Lembaga</label>
              <div class="col-sm-7">
                <input
                  :value="pesertadidik.lembaga ? pesertadidik.lembaga : '-'"
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>

            <div class="row">
              <label class="col-sm-4 col-form-label">Jurusan</label>
              <div class="col-sm-7">
                <input
                  :value="pesertadidik.jurusan ? pesertadidik.jurusan : '-'"
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>

            <!-- <div class="row">
              <label class="col-sm-4 col-form-label">Kelas</label>
              <div class="col-sm-7">
                <input
                  :value="pesertadidik.kelas ? pesertadidik.kelas : '-'"
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div> -->

            <hr class="my-2" />
            <!-- Ukuran Seragam -->
            <div class="row">
              <label class="col-sm-4 col-form-label">Ukuran Seragam</label>
              <div class="col-sm-7">
                <input
                  :value="
                    pesertadidik.ukuran_seragam
                      ? pesertadidik.ukuran_seragam
                      : '-'
                  "
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>

            <div class="row">
              <label class="col-sm-4 col-form-label">
                Detail Ukuran Seragam
              </label>
              <div class="col-sm-7">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Panjang Baju</th>
                      <th scope="col">Panjang Lengan</th>
                      <th scope="col">Lingkar Badan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="detail in useDetailSeragam" :key="detail">
                        {{ detail }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <label class="col-sm-4 col-form-label"></label>
              <div class="col-sm-8" style="color: #ff6700; font-size: 85%">
                <strong>NOTE:</strong> Silahkan pilih ukuran seragam dengan
                hati-hati. Saat pemberian logistik, ukuran seragam sesuai yang
                dipilih dan tidak bisa diganti ke ukuran lain.
              </div>
            </div>

            <hr />
            <div class="row">
              <label class="col-sm-4 col-form-label"
                >Nomor Induk Siswa Nasional (NISN)</label
              >
              <div class="col-sm-7">
                <input
                  :value="pesertadidik.nisn ? pesertadidik.nisn : '-'"
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>

            <div class="row">
              <label class="col-sm-4 col-form-label"
                >Alumni dari lembaga pendidikan di NJ?</label
              >
              <div class="col-sm-7">
                <input
                  :value="pesertadidik.niup ? 'Ya' : 'Tidak'"
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>
            <div class="row" v-if="pesertadidik.niup">
              <label class="col-sm-4 col-form-label pl-5">&#x2022; NIUP</label>
              <div class="col-sm-7">
                <input
                  :value="pesertadidik.niup ? pesertadidik.niup : '-'"
                  type="text"
                  readonly
                  class="form-control-plaintext form-control-sm"
                />
              </div>
            </div>
            <div
              class="row"
              v-if="
                pesertadidik.niup &&
                biodatas.pesertadidik.alumni_pendidikan.lembaga
              "
            >
              <label class="col-sm-4 col-form-label pl-5"></label>
              <div class="col-sm-7 text-secondary font-weight-light">
                <i
                  >Terdeteksi lembaga pendidikan sebelumnya di Nurul Jadid
                  yaitu:</i
                ><br />
                <b>{{ biodatas.pesertadidik.alumni_pendidikan.lembaga }}</b>
              </div>
            </div>
          </div>

          <div class="col-md-12" v-if="editmode">
            <div class="form-group row mb-2">
              <label class="col-sm-4 col-form-label">Mondok</label>
              <div class="col-sm-7">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        v-model="isian.is_mondok"
                        type="radio"
                        name="inlineRadioOptions"
                        id="mondok-ya"
                        value="Y"
                      />
                      <label class="form-check-label" for="mondok-ya">Ya</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        v-model="isian.is_mondok"
                        type="radio"
                        name="inlineRadioOptions"
                        id="mondok-tidak"
                        value="T"
                      />
                      <label class="form-check-label" for="mondok-tidak"
                        >Tidak</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="jenis_pendaftaran" class="col-sm-4 col-form-label"
                >Jenis Pendaftaran</label
              >
              <div class="col-sm-7">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <select
                      name="Jenis Pendaftaran"
                      v-validate="'required'"
                      :class="{ 'is-invalid': errors.has('Jenis Pendaftaran') }"
                      v-model="isian.jenis_daftar"
                      id="jenis_pendaftaran"
                      class="form-control form-control-sm"
                    >
                      <option :value="null">Pilih Jenis Pendaftaran</option>
                      <option :value="'BARU'">Baru</option>
                      <option :value="'MUTASI'">Mutasi</option>
                    </select>
                    <div
                      v-show="errors.has('Jenis Pendaftaran')"
                      class="invalid-feedback"
                      v-text="errors.first('Jenis Pendaftaran')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="mt-0 mb-3" />

            <div class="form-group row">
              <label for="lembaga" class="col-sm-4 col-form-label"
                >Lembaga</label
              >
              <div class="col-sm-7">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <select
                      name="Lembaga"
                      :class="{ 'is-invalid': errors.has('Lembaga') }"
                      v-model="isian.id_lembaga"
                      id="lembaga"
                      class="form-control form-control-sm"
                      @change="handleLembaga('new')"
                    >
                      <option :value="null">Pilih Lembaga</option>
                      <option
                        v-for="(lembaga, id) in master.lembaga"
                        :key="id"
                        :value="lembaga.id"
                        v-text="lembaga.nama"
                      ></option>
                    </select>
                    <div
                      v-show="errors.has('Lembaga')"
                      class="invalid-feedback"
                      v-text="errors.first('Lembaga')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label for="jurusan" class="col-sm-4 col-form-label"
                >Jurusan</label
              >
              <div class="col-sm-7">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <select
                      name="Jurusan"
                      :class="{ 'is-invalid': errors.has('Jurusan') }"
                      v-model="isian.id_jurusan"
                      id="jurusan"
                      class="form-control form-control-sm"
                    >
                      <option :value="null">Pilih Jurusan</option>
                      <option
                        v-for="(jurusan, id) in master.jurusan"
                        :key="id"
                        :value="jurusan.id"
                        v-text="jurusan.nama"
                      ></option>
                    </select>
                    <div
                      v-show="errors.has('Jurusan')"
                      class="invalid-feedback"
                      v-text="errors.first('Jurusan')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div class="form-group mb-1">
              <div class="row">
                <legend class="col-form-label col-sm-4 pt-0">
                  Ukuran Seragam
                </legend>
                <div class="col-sm-7">
                  <div
                    class="form-check form-check-inline"
                    v-for="(item, index) in listDetailUkuran"
                    :key="index"
                  >
                    <input
                      name="ukuran_seragam"
                      v-validate="
                        'required|included:XXS,XS,S,M,L,XL,XXL,XXXL,XXXXL'
                      "
                      v-model="isian.ukuran_seragam"
                      class="form-check-input"
                      type="radio"
                      @change="onDetailSeragam(index)"
                      :id="item.id"
                      :value="item.id"
                    />
                    <label class="form-check-label" :for="item.id">{{
                      item.label
                    }}</label>
                  </div>
                  <div
                    v-show="errors.has('ukuran_seragam')"
                    class="invalid-feedback"
                    style="display: block"
                    v-text="errors.first('ukuran_seragam')"
                  ></div>
                </div>
              </div>
            </div>

            <div class="form-group mb-1 mt-3" v-if="isian.ukuran_seragam">
              <div class="row">
                <legend class="col-form-label col-sm-4 pt-0">
                  Detail Ukuran Seragam
                </legend>
                <div class="col-sm-7">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Panjang Baju</th>
                        <th scope="col">Panjang Lengan</th>
                        <th scope="col">Lingkar Badan</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td v-for="detail in useDetailSeragam" :key="detail">
                          {{ detail }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row">
              <label class="col-sm-4 col-form-label"></label>
              <div class="col-sm-8" style="color: #ff6700; font-size: 85%">
                <strong>NOTE:</strong> Silahkan pilih ukuran seragam dengan
                hati-hati. Saat pemberian logistik, ukuran seragam sesuai yang
                dipilih dan tidak bisa diganti ke ukuran lain.
              </div>
            </div>

            <hr />

            <div class="form-group row">
              <label for="NISN" class="col-sm-4 col-form-label"
                >Nomor Induk Siswa Nasional (NISN)</label
              >
              <div class="col-sm-7">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <input
                      name="NISN"
                      v-validate="'numeric|digits:10'"
                      :class="{ 'is-invalid': errors.has('NISN') }"
                      v-model="isian.nisn"
                      type="text"
                      class="form-control form-control-sm"
                      id="NISN"
                      placeholder="NISN"
                    />
                    <div
                      v-show="errors.has('NISN')"
                      class="invalid-feedback"
                      v-text="errors.first('NISN')"
                    ></div>
                    <label
                      for="NISN"
                      style="color: #ccc; font-size: 11px; font-style: italic"
                      >Opsional, diisi jika ada</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 col-form-label"
                >Alumni dari lembaga pendidikan di NJ?</label
              >
              <div class="col-sm-7">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        v-validate="'required'"
                        v-model="is_alumni"
                        type="radio"
                        name="is_alumni"
                        id="T"
                        :value="false"
                      />
                      <label class="form-check-label" for="T">Tidak</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        v-model="is_alumni"
                        type="radio"
                        name="is_alumni"
                        id="Y"
                        :value="true"
                      />
                      <label class="form-check-label" for="Y">Ya</label>
                    </div>
                    <div
                      v-show="errors.has('is_alumni')"
                      class="invalid-feedback"
                      v-text="errors.first('is_alumni')"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" v-if="is_alumni">
              <label for="NIUP" class="col-sm-4 col-form-label pl-5"
                >&#x2022; NIUP</label
              >
              <div class="col-sm-7">
                <div class="form-row align-items-center">
                  <div class="col-auto">
                    <input
                      name="NIUP"
                      v-validate="'numeric|digits:11'"
                      :class="{ 'is-invalid': errors.has('NIUP') }"
                      v-model="isian.niup"
                      type="text"
                      class="form-control form-control-sm"
                      id="NIUP"
                      placeholder="NIUP"
                    />
                    <div
                      v-show="errors.has('NIUP')"
                      class="invalid-feedback"
                      v-text="errors.first('NIUP')"
                    ></div>
                    <label
                      for="NIUP"
                      style="color: #ccc; font-size: 11px; font-style: italic"
                      >wajib diisi jika alumni dari salah satu lembaga di
                      NJ</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listDetailUkuran from "../../plugins/api/listDetailUkuran";
export default {
  props: ["biodatas"],
  data() {
    return {
      is_alumni: false,
      pesertadidik: {},
      editmode: false,
      isian: {
        jenis_daftar: null,
        id_lembaga: null,
        id_jurusan: null,
        id_kelas: null,
        ukuran_seragam: null,
        nisn: null,
        niup: null,
      },
      jenis_kelamin: "",
      master: {
        lembaga: [],
        jurusan: [],
        kelas: [],
      },
      listDetailUkuran: listDetailUkuran,
      usedUkuran: "",
      useDetailSeragam: {},
    };
  },
  methods: {
    logical(jenis) {
      switch (jenis) {
        case "Y":
          return "Ya";
        case "T":
          return "Tidak";
        default:
          return "-";
      }
    },
    refreshBio() {
      let null_pendidik = {
        id_lembaga: null,
        id_jurusan: null,
        // id_kelas: null,
      };
      if (this.biodatas && this.biodatas.pesertadidik) {
        this.pesertadidik =
          this.biodatas.pesertadidik.pendidikan || null_pendidik;
        this.pesertadidik.is_mondok = this.biodatas.pesertadidik.is_mondok;
        this.pesertadidik.jenis_daftar =
          this.biodatas.pesertadidik.jenis_daftar;
        this.pesertadidik.ukuran_seragam =
          this.biodatas.pesertadidik.ukuran_seragam;
        this.jenis_kelamin = this.biodatas.jenis_kelamin;

        this.pesertadidik.nisn = this.biodatas.pesertadidik.nisn;
        this.pesertadidik.niup = this.biodatas.pesertadidik.niup;

        this.is_alumni = !!this.biodatas.pesertadidik.niup;
      } else {
        this.pesertadidik.pendidikan = null_pendidik;
        this.pesertadidik.is_mondok = null;
        this.pesertadidik.jenis_daftar = null;
        this.is_alumni = false;
      }
    },
    action() {
      let doit = () => {
        this.$store.commit("setLoading", false);
        this.$axios
          .put("/pendaftar/" + this.biodatas.uuid + "/pesertadidik", {
            is_mondok: this.isian.is_mondok,
            jenis_daftar: this.isian.jenis_daftar,
            ukuran_seragam: this.isian.ukuran_seragam,
            pendidikan: {
              id_lembaga: this.isian.id_lembaga,
              id_jurusan: this.isian.id_jurusan,
              // id_kelas: this.isian.id_kelas
            },
            nisn: this.isian.nisn ? this.isian.nisn : null,
            niup: this.is_alumni
              ? this.isian.niup
                ? this.isian.niup
                : null
              : null,
          })
          .then((res) => {
            this.$root.$emit("setNotif", {
              title: res.data.status,
              message: res.data.deskripsi,
              type: "success",
            });
            this.$store.commit("setLoading", false);
            this.editmode = false;
            this.$emit("refreshBio", true);
          })
          .catch(() => {});
      };

      this.$validator.validateAll().then((result) => {
        if (result) doit();
      });
    },
    editTrigger() {
      this.getLembaga();
      this.editmode = true;
      this.isian = {
        jenis_daftar: this.pesertadidik.jenis_daftar,
        id_lembaga: this.pesertadidik.id_lembaga,
        id_jurusan: this.pesertadidik.id_jurusan,
        id_kelas: this.pesertadidik.id_kelas,
        is_mondok: this.pesertadidik.is_mondok,
        ukuran_seragam: this.pesertadidik.ukuran_seragam,
        nisn: this.pesertadidik.nisn,
        niup: this.pesertadidik.niup,
      };
      this.handleLembaga();
    },
    handleLembaga(mode) {
      if (this.isian.id_lembaga) {
        this.$axios
          .get("/setting/lembaga/jurusan?lembaga=" + this.isian.id_lembaga)
          .then((res) => {
            this.master.jurusan = res.data;
          })
          .catch(() => {});
        this.$axios
          .get("/setting/lembaga/kelas?lembaga=" + this.isian.id_lembaga)
          .then((res) => {
            this.master.kelas = res.data;
          })
          .catch(() => {});
        if (mode == "new") {
          this.isian.id_kelas = null;
          this.isian.id_jurusan = null;
        }
      } else {
        this.master.jurusan = [];
        this.master.kelas = [];
        this.isian.id_kelas = null;
        this.isian.id_jurusan = null;
      }
    },
    getLembaga() {
      this.$axios
        .get("/setting/lembaga")
        .then((res) => {
          this.master.lembaga = res.data;
        })
        .catch(() => {});
    },
    getDetailSeragam() {
      const santriUkuran = this.pesertadidik.ukuran_seragam;
      const findUkuran = this.listDetailUkuran.filter(function (el) {
        return el.id == santriUkuran;
      });
      if (this.jenis_kelamin == "L") {
        this.useDetailSeragam = findUkuran[0]?.putra;
      } else {
        this.useDetailSeragam = findUkuran[0]?.putri;
      }
    },
    onDetailSeragam(id) {
      if (this.jenis_kelamin == "L") {
        this.useDetailSeragam = this.listDetailUkuran[id]?.putra;
      } else {
        this.useDetailSeragam = this.listDetailUkuran[id]?.putri;
      }
    },
  },
  created() {
    this.refreshBio();
  },
  watch: {
    biodatas: {
      handler() {
        this.refreshBio();
        this.getDetailSeragam();
      },
      deep: true,
    },
  },
  mounted() {
    this.getDetailSeragam();
  },
};
</script>
