export default [
  {
    id: "XXS",
    label: "2XS",
    putra: {
      PJ: "57 cm",
      PL: "22 cm",
      LB: "85 cm",
    },
    putri: {
      PJ: "57 cm",
      PL: "48 cm",
      LB: "85 cm",
    },
  },
  {
    id: "XS",
    label: "XS",
    putra: {
      PJ: "60 cm",
      PL: "23 cm",
      LB: "90 cm",
    },
    putri: {
      PJ: "60 cm",
      PL: "50 cm",
      LB: "90 cm",
    },
  },
  {
    id: "S",
    label: "S",
    putra: {
      PJ: "63 cm",
      PL: "24 cm",
      LB: "95 cm",
    },
    putri: {
      PJ: "63 cm",
      PL: "52 cm",
      LB: "95 cm",
    },
  },
  {
    id: "M",
    label: "M",
    putra: {
      PJ: "66 cm",
      PL: "25 cm",
      LB: "100 cm",
    },
    putri: {
      PJ: "66 cm",
      PL: "54 cm",
      LB: "100 cm",
    },
  },
  {
    id: "L",
    label: "L",
    putra: {
      PJ: "69 cm",
      PL: "26 cm",
      LB: "105 cm",
    },
    putri: {
      PJ: "69 cm",
      PL: "56 cm",
      LB: "105 cm",
    },
  },
  {
    id: "XL",
    label: "XL",
    putra: {
      PJ: "72 cm",
      PL: "27 cm",
      LB: "110 cm",
    },
    putri: {
      PJ: "72 cm",
      PL: "58 cm",
      LB: "110 cm",
    },
  },
  {
    id: "XXL",
    label: "2XL",
    putra: {
      PJ: "74 cm",
      PL: "28 cm",
      LB: "115 cm",
    },
    putri: {
      PJ: "74 cm",
      PL: "60 cm",
      LB: "115 cm",
    },
  },
  {
    id: "XXXL",
    label: "3XL",
    putra: {
      PJ: "77 cm",
      PL: "29 cm",
      LB: "120 cm",
    },
    putri: {
      PJ: "77 cm",
      PL: "61 cm",
      LB: "120 cm",
    },
  },
  {
    id: "XXXXL",
    label: "4XL",
    putra: {
      PJ: "80 cm",
      PL: "30 cm",
      LB: "125 cm",
    },
    putri: {
      PJ: "80 cm",
      PL: "62 cm",
      LB: "125 cm",
    },
  },
];
